import { ComponentWithChildren } from "@nvon/react-toolbox";
import { createContext, useCallback, useContext, useState } from "react";
import { truendoSiteId } from "../../../helpers/clientSide/constants";
import { useEventListener } from "../../../hooks/useEventListener";

import { initialConsentData } from "./initialConsentData";
import TruendoCookieConsent from "./TruendoCookieConsent";
import {
  TruendoConsentData,
  TruendoCookieControlEvent,
} from "./TruendoCookieConsentInterfaces";

const TruendoCookieControlEventType = "TruendoCookieControl";

const TruendoConsentContext =
  createContext<TruendoConsentData>(initialConsentData);

export const useConsentData = (): TruendoConsentData =>
  useContext(TruendoConsentContext);

/**
 * Renders the truendo consent manager, and provides all child components
 * access to consent data via context.
 */
export const TruendoConsentProvider: ComponentWithChildren = ({ children }) => {
  const [consentData, setConsentData] =
    useState<TruendoConsentData>(initialConsentData);

  const eventCallback = useCallback((event: Event) => {
    setConsentData((event as TruendoCookieControlEvent).detail);
  }, []);

  useEventListener(TruendoCookieControlEventType, eventCallback);

  return (
    <>
      <TruendoCookieConsent truendoSiteId={truendoSiteId} />

      <TruendoConsentContext.Provider value={consentData}>
        {children}
      </TruendoConsentContext.Provider>
    </>
  );
};
