import {
  Breakpoint,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

export const mobileMenuBreakpoint: Breakpoint = "lg";

const primaryColor = "#9DDAC6"; // Mint
const secondaryColor = "#263238"; // Dark greenish-grey
const black = "#000000";
const white = "#FFFFFF";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    text: {
      primary: black,
      secondary: secondaryColor,
    },
    background: {
      default: white,
      paper: primaryColor,
    },
  },
  typography: {
    fontFamily: "Outfit, Verdana, Geneva, sans-serif",
    fontWeightRegular: 500,
    fontWeightBold: 800,
    allVariants: {
      letterSpacing: 0,
      textAlign: "left",
      fontWeight: 500,
    },
    h1: {
      fontSize: "3.75rem",
    },
    h2: {
      fontSize: "3.25rem",
    },
    h3: {
      fontSize: "3rem",
    },
    h4: {
      fontSize: "2rem",
    },
    h5: {
      fontSize: "1.5rem",
    },
    h6: {
      fontSize: "1.25rem",
    },
    body1: {
      fontSize: "1.2rem",
    },
    body2: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "1.1rem",
      textTransform: "none",
    },
  },
  spacing: (factor: number) => `${1 * factor}rem`,
});

const themeWithOverrides = {
  ...theme,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ".single-line": {
          display: "inline-block",
        },
        "img.emoji": {
          width: "1em",
          height: "1em",
        },
        a: {
          color: theme.palette.primary.dark,
          textDecoration: "none",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        square: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: theme.typography.fontWeightBold,
          maxWidth: theme.breakpoints.values.md,
        },
        h2: {
          fontWeight: theme.typography.fontWeightBold,
          maxWidth: theme.breakpoints.values.md,
        },
        h3: {
          lineHeight: "2.8rem",
          fontWeight: theme.typography.fontWeightBold,
          maxWidth: theme.breakpoints.values.md,
        },
        h4: {
          lineHeight: "2.5rem",
          fontWeight: theme.typography.fontWeightBold,
          marginBottom: "0.75rem",
          maxWidth: theme.breakpoints.values.md,
        },
        h5: {
          lineHeight: "2rem",
          fontWeight: theme.typography.fontWeightBold,
          marginBottom: "0.75rem",
          maxWidth: theme.breakpoints.values.md,
        },
        h6: {
          lineHeight: "2rem",
          fontWeight: theme.typography.fontWeightBold,
          maxWidth: theme.breakpoints.values.md,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1.4rem",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: `1px solid ${theme.palette.grey[300]}`,
          backgroundColor: theme.palette.background.default,
          borderRadius: 0,
          height: "100%",
          width: "100%",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: `${theme.spacing(2)} !important`,
          height: "100%",
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl" as Breakpoint,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "0px 0px 5px 5px",
          maxWidth: theme.breakpoints.values.md,
          boxShadow: "none",
          border: `1px solid rgba(224, 224, 224)`,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: { borderRadius: "0px 0px 5px 5px" },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 2),
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        defaultProps: {
          color: "secondary",
        },
        root: {
          borderRadius: "0px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: theme.spacing(0.5, 1.25),
        },
        contained: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        },
        outlined: {
          "&:hover": {
            borderWidth: "2px",
          },
        },
        outlinedPrimary: {
          color: theme.palette.secondary.main,
          border: `2px solid ${theme.palette.secondary.main}`,
          "&:hover": {
            border: `2px solid ${theme.palette.secondary.main}`,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.background.default,
          },
        },
        outlinedSecondary: {
          color: theme.palette.background.default,
          border: `2px solid ${theme.palette.background.default}`,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            border: `2px solid ${theme.palette.background.default}`,
          },
          "@media(hover: none)": {
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.background.default,
              border: `2px solid ${theme.palette.background.default}`,
            },
          },
        },
      },
    },
  },
};

const themeWithResponsiveFontSizes = responsiveFontSizes(themeWithOverrides, {
  factor: 3,
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
});

export default themeWithResponsiveFontSizes;
