import { useCallback, useEffect } from "react";

export const useEventListener = (
  eventType: string,
  eventCallback: (event: Event) => void
): void => {
  const addListener = useCallback(() => {
    window.addEventListener(eventType, eventCallback);
  }, [eventCallback, eventType]);

  const removeListener = useCallback(() => {
    window.addEventListener(eventType, eventCallback);
  }, [eventCallback, eventType]);

  useEffect(() => {
    addListener();

    return () => {
      removeListener();
    };
  }, [addListener, removeListener]);
};
