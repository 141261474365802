import { CacheProvider, EmotionCache } from "@emotion/react";
import "@fontsource/outfit/500.css";
import "@fontsource/outfit/800.css";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { appWithTranslation } from "next-i18next";
import withTranslateRoutes from "next-translate-routes";
import { AppProps } from "next/app";
import GoogleSearchConsoleVerification from "../src/components/00-globals/GoogleSearchConsoleVerification/GoogleSearchConsoleVerification";
import { TruendoConsentProvider } from "../src/components/00-globals/TruendoCookieConsent/TruendoConsentProvider";
import TruendoGoogleTagManager from "../src/components/00-globals/TruendoGoogleTagManager/TruendoGoogleTagManager";
import { TruendoHotjarScript } from "../src/components/00-globals/TruendoHotjarScript/TruendoHotjarScript";
import createEmotionCache from "../src/components/01-atoms/createEmotionCache";
import {
  googleTrackingId,
  hotjarId,
  verificationId,
} from "../src/helpers/clientSide/constants";
import theme from "../src/layouts/theme";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function MyApp(props: MyAppProps): JSX.Element {
  const { Component, emotionCache = clientSideEmotionCache } = props;

  return (
    <CacheProvider value={emotionCache}>
      <GoogleSearchConsoleVerification verificationId={verificationId} />

      <TruendoConsentProvider>
        <TruendoGoogleTagManager googleTrackingId={googleTrackingId} />

        <TruendoHotjarScript hotjarId={hotjarId} />

        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Component {...props.pageProps} />
        </ThemeProvider>
      </TruendoConsentProvider>
    </CacheProvider>
  );
}

export default withTranslateRoutes(JSON.parse(`{"defaultLocale":"de","locales":["en","de"],"routesTree":{"name":"","paths":{"default":""},"children":[{"name":"datenschutz","paths":{"default":"datenschutz","en":"privacy-policy","de":"datenschutz"}},{"name":"impressum","paths":{"default":"impressum","en":"imprint","de":"impressum"}},{"name":"index","paths":{"default":"index"}},{"name":"projects","paths":{"default":"projects","en":"projects","de":"projects"},"children":[{"name":"[project]","paths":{"default":":project"}},{"name":"index","paths":{"default":"index"}}]},{"name":"services","paths":{"default":"services","en":"services","de":"services"},"children":[{"name":"[...service]","paths":{"default":":service"}},{"name":"index","paths":{"default":"index"}}]},{"name":"team","paths":{"default":"team","en":"team","de":"team"}},{"name":"video","paths":{"default":"video"}}]}}`), appWithTranslation(MyApp));
