import { TruendoConsentData } from "./TruendoCookieConsentInterfaces";

/**
 * The consent initially set before the user makes any adjustments.
 */
export const initialConsentData: TruendoConsentData = {
  necessary: true,
  preferences: false,
  statistics: false,
  marketing: false,
  services: {
    google_analytics: false,
    truendo_privacy_mgmt: true,
  },
};
