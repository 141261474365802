import Head from "next/head";
import { useEffect } from "react";
import { useConsentData } from "../TruendoCookieConsent/TruendoConsentProvider";
import { startGoogleTagManager } from "./startGoogleTagManager";
import { TruendoGoogleTagManagerProps } from "./TruendoGoogleTagManagerInterfaces";

const TruendoGoogleTagManager = (
  props: TruendoGoogleTagManagerProps
): JSX.Element => {
  const genericScriptSource =
    "https://www.googletagmanager.com/gtm.js?id=" +
    props.googleTrackingId +
    "&l=dataLayer";

  const tagManagerAccepted = useConsentData().statistics;

  useEffect(() => {
    if (tagManagerAccepted) {
      startGoogleTagManager();
    }
  }, [tagManagerAccepted]);

  return (
    <Head>
      <script
        type="text/plain"
        data-type="application/javascript"
        data-trucookiecontrol="statistics"
        key="ga-generic-script"
        async={true}
        src={genericScriptSource}
      />
    </Head>
  );
};

export default TruendoGoogleTagManager;
