import theme from "../../layouts/theme";

/**
 * The max width of containerized content.
 * This is applied to the <Container> element on xl viewports.
 *
 * All other viewports currently use the default maxWidth.
 */
export const containerMaxWidth = 1600;
export const containerPaddingBigDevices = 8;
export const containerPaddingSmallDevicesTopBottom = 5;
export const containerPaddingSmallDevicesLeftRight = 1;
export const headerHeight = 80; // px

/*
 * Devices size.
 * These constants are used in the prop "sizes" of the nextjs Image component.
 */
export const mobileDevices = `(max-width: ${theme.breakpoints.values.sm}px)`; // Devices up to sm breakpoints, i.e., xs breakpoints
export const upToSmallDevices = `(max-width: ${theme.breakpoints.values.md}px)`; // Devices up to md breakpoints, i.e., xs and sm breakpoints
export const upToMediumDevices = `(max-width: ${theme.breakpoints.values.lg}px)`; // Devices up to lg breakpoints, i.e., xs, sm and md breakpoints

// See https://day.js.org/docs/en/display/format#list-of-localized-formats
export const dateFormatLong = "L";

export const dataPrivacyUrl = "/datenschutz";

export const dateFormatMonth = "MMMM YYYY";

export const scrollTriggerConstant = (
  isMobile: boolean
): { disableHysteresis: boolean; threshold: number } => ({
  disableHysteresis: true,
  threshold: isMobile ? 30 : 100,
});

export const truendoSiteId = "b5a6063c-738d-4132-ada1-16d1d2a1f8c6";

export const googleTrackingId = "GTM-K776ZMT";

export const hotjarId = 3085542;

export const verificationId = "GheWDdxIQnlgVwq3XSMr2Qzxst8N5zJrlkVs7EMMF38";

/**
 * In production, the application state (redux) is persisted in local storage.
 * On development, this flag can used to disable this behaviour.
 */
export const stateSyncingInDevelopmentMode = true;
