import { useEffect } from "react";
import { useConsentData } from "../TruendoCookieConsent/TruendoConsentProvider";
import { runHotjar } from "./runHotjar";

export const TruendoHotjarScript = ({
  hotjarId,
}: {
  hotjarId: number;
}): null => {
  const isAllowed = useConsentData().statistics;

  useEffect(() => {
    if (isAllowed) {
      runHotjar(hotjarId);
    }
  }, [hotjarId, isAllowed]);

  /*
   * Yes, this could be a hook instead.
   * It's implemented as a component to be consistent with the other TruendoXY components.
   */
  return null;
};
