import { AnyObject } from "@nvon/baseline";

declare global {
  interface Window {
    dataLayer: AnyObject[];
  }
}

export const initializeDataLayer = (): void => {
  window.dataLayer = window.dataLayer || [];
};

export const startGoogleTagManager = (): void => {
  initializeDataLayer();
  window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
};
