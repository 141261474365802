import Head from "next/head";
import { GoogleSearchConsoleVerificationProps } from "./GoogleSearchConsoleVerificationInterfaces";

const GoogleSearchConsoleVerification = (
  props: GoogleSearchConsoleVerificationProps
): JSX.Element => {
  return (
    <Head>
      <meta name="google-site-verification" content={props.verificationId} />
    </Head>
  );
};

export default GoogleSearchConsoleVerification;
